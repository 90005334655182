import Vue from "vue";
import Vuex from "vuex";
import * as storage from "@/utils/storage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentDevice: 1, // 1：PC, 0:mobile
    loadNumber: 0,
    webToken: storage.getStore("webToken") || null,
    webUserInfo: storage.getStore("webUserInfo") || {},
  },
  mutations: {
    SET_CURRENT_DEVICE(state, value) {
      state.currentDevice = value;
    },
    SET_LOAD_NUMBER(state, value) {
      state.loadNumber = value;
    },
    SET_AUTH_INFO(state, value) {
      state.webUserInfo = value;
    },
    SET_TOKEN(state, value) {
      state.webToken = value;
    },
  },
});
