<template>
  <div class="pc-layout">
    <div class="pc-header">
      <div class="warp">
        <div class="pc-logo" @click="jumpToHome">
          <img class="pc-img" src="@/assets/logo.gif" alt="logo" />
          <div class="pc-title-body">
            <div class="pc-title">四川省执业药师继续教育培训</div>
            <div class="pc-sub">
              SI CHUAN SHENG ZHI YE YAO SHI JI XU JIAO YU PEI XUN
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pc-body">
      <div class="warp">
        <div class="pages-box">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </div>
      </div>
    </div>
    <div class="pc-footer">
      <div class="warp">
        <p>
          版权所有：四川中科信息技术专修学院
          &nbsp;&nbsp;&nbsp;&nbsp;电话：028-85531022
        </p>
        <p>地址：小南街109号西府少城3栋1单元22楼</p>
        <p>蜀ICP备17002527号</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PcLayout",
  methods: {
    jumpToHome() {
      if (this.$route.name !== "signUp" && this.$route.name !== "specialized") {
        this.keepAliveNavigation("/specialized");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pc-header {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(180deg, #920705 0%, #740705 100%);
  .pc-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    .pc-img {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
    .pc-title-body {
      color: #ffffff;
      .pc-title {
        font-size: 21.5px;
      }
      .pc-sub {
        margin-top: 2px;
        font-size: 10px;
      }
    }
  }
}
.pc-user-info {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #7c3e3d;
  padding: 28px 0;
  .warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.pc-avatar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .avatar-img {
    width: 84px;
    height: 84px;
    overflow: hidden;
    border-radius: 100px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-name {
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 4px;
  }
  .avatar-value {
    color: rgba(255, 255, 255, 0.8);
  }
}
.pc-study-info {
  background: rgba(0, 0, 0, 0.1);
  padding: 12px 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .study-info-item {
    text-align: center;
    margin: 0 20px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .item-value {
      font-size: 30px;
      color: #ffffff;
    }
    .item-label {
      margin-top: 4px;
      font-size: 14px;
      color: #b49797;
    }
  }
  .study-line {
    width: 2px;
    height: 40px;
    background-color: #926c6c;
    margin: 0 5px;
  }
}
.warp {
  width: 1260px;
  margin: 0 auto;
}
.pc-body {
  min-height: calc(100vh - 193px);
  background-color: #f5f5f5;
  .pages-box {
    flex: 80%;
  }
}
.pc-footer {
  background-color: #d60a11;
  padding: 20px 0;
  text-align: center;
  p {
    margin: 0 0 10px 0;
    color: #fff;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
